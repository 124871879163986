<template>
  <!-- Vertical -->
  <table class="wp-100" v-if="type === 'vertical'">
    <tr v-for="(item, index) in modifyDataEnquiryDetail" :key="index">
      <th class="pl-16 pr-16 pt-11 pb-11 w-200 tc" v-show="item.data">{{ item.header }}</th>
      <td class="pl-16 pr-16 pt-11 pb-11" v-show="item.data">
        <a :href="datas.url" v-if="index === 0 && item.header !== 'Sample Itinerary'" target="_blank" style="color: #000 !important; text-decoration: underline !important">{{ item.data }}</a>
        <span v-else-if="index !== 0 && item.header === 'Sample Itinerary'" target="_blank" style="color: #000 !important; text-decoration: underline !important">
          <a :href="item.data.split(',')[0]" target="_blank" v-if="item.data && item.data.split(',')[0]" style="word-break: break-word;">{{ item.data.split(',')[0] }}</a>
          <div class="dib" v-if="item.data && item.data.split(',')[0].length > 1 && item.data.split(',')[1].length > 1">, </div>
          <a :href="item.data.split(',')[1]" target="_blank" v-if="item.data && item.data.split(',')[1]" style="word-break: break-word;">{{ item.data.split(',')[1] }}</a>
        </span>
        <span v-else v-html="item.data"></span>
      </td>
    </tr>
  </table>
</template>
<script>
import { ref, watchEffect } from 'vue'
export default {
  props: {
    datas: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const modifyDataEnquiryDetail = ref([])
    watchEffect(() => {
      modifyDataEnquiryDetail.value = [
        {
          header: 'Trip Plan Selected',
          data: props.datas.trip_selected,
        },
        // {
        //   header: 'Special Deal',
        //   data: process.env.VUE_APP_ENQUIRY_SINGLE ? undefined : props.datas.special_deals,
        // },
        {
          header: 'Destination',
          data: props.datas.destinations,
        },
        {
          header: 'Number of Travellers',
          data: props.datas.travellers,
        },
        {
          header: 'Travel Dates',
          data: props.datas.dates ? props.datas.dates + (parseInt(props.datas.duration) ? ', ' + props.datas.duration : '') : undefined,
        },
        {
          header: 'Type of Activity',
          data: props.datas.activities,
        },
        {
          header: 'Type of Accommodation',
          data: props.datas.accm_pref.split(',').join(', '),
        },
        {
          header: 'Total Budget',
          data: props.datas.budget,
        },
        /*{
          header: 'Submitted From',
          data: props.datas.submmit_from,
        },*/
        {
          header: 'Trip Details',
          data: props.datas.additional_comments,
        },
        {
          header: props.datas.agent_name ? 'Enquired by' : undefined,
          data: props.datas.agent_name ? props.datas.agent_name : undefined
        },
        {
          header: 'Sample Itinerary',
          data: props.datas.suggest_external_upload || props.datas.suggest_external_link
              ? props.datas.suggest_external_upload + ', ' + props.datas.suggest_external_link : undefined,
        },
        {
          header: props.datas.exact_date ? 'Flights' : undefined,
          data: !props.datas.exact_date ? undefined : props.datas.has_flight_ticket ? 'I have booked my flights.' : 'I have not booked my flights yet.',
        },
        {
          header: 'Ideal Room Configuration',
          data: props.datas.ideal_room_config ? props.datas.ideal_room_config : undefined,
        },
        {
          header: 'Additional Comments',
          data: props.datas.note ? props.datas.note : undefined,
        }
      ]
      if(props?.datas?.type === 'Group') {
        modifyDataEnquiryDetail.value.unshift(
          {
          header: 'Enquiry Type',
          data: props.datas.type,
        }
        )
      }
    })

    return { modifyDataEnquiryDetail }
  },
}
</script>

<style lang="scss">
table,
th,
td {
  border: 1px solid $gray-medium;
  border-collapse: collapse;
  tr {
    th {
      font-weight: 600; font-family: $font-family-proximanova-semibold;
      font-size: 14px;
      line-height: 18px;
      color: $black;
      background: $gray-branding;
    }
    td {
      font-size: 14px;
      line-height: 18px;
      color: $black;
    }
  }
}
</style>
